jQuery(document).ready(function ($) {
    $('.dropdown-toggle').click(function (event) {
        //Prevent # showing in navigation
        event.preventDefault();
        $(this).parent().toggleClass('expand-dropdown');
    })
    $('#cpd-module-page-btn').click(function (event) {
        
        $('#cpd-module-pages').slideToggle( "slow", function() {
    // Animation complete.
         });
    } );   
    $('#cpd-general-menu-btn').click(function (event) {
        
        $('#bootstrap_cpd_general_nav').slideToggle( "slow", function() {
    // Animation complete.
         });
    } );  
    
    
    function cpd_move_around_fn(){
         // toggle cpd general to bottom
        if( $( window ).width() < 768 ){
            $("#cpd-move-around").appendTo("#cpd-move-around-anker-bottom");
        } else {
            $("#cpd-move-around").appendTo("#cpd-move-around-anker");
        }   
    }
    cpd_move_around_fn();
    $( window ).resize( function() {
        cpd_move_around_fn();
    } )    
});
/**
 * get script
 * @param  string   source   url
 * @param  Function callback function to call back
 * @link   https://stackoverflow.com/questions/16839698/jquery-getscript-alternative-in-native-javascript
 * @return null
 */
function get_script_fn( source, callback ) {
    var script 		= document.createElement('script');
    var prior 		= document.getElementsByTagName('script')[0];
    script.async 	= 1;

    script.onload = script.onreadystatechange = function( _, isAbort ) {
        if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
            script.onload = script.onreadystatechange = null;
            script = undefined;

            if(!isAbort) { if(callback) callback(); }
        }
    };

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
    
}

